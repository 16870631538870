import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql } from 'gatsby';
import React, { Dispatch, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import DiscountPanel from 'components/DiscountPanel';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import { Link } from 'components/PolicyDocuments';
import PolicyLink from 'components/PolicyDocuments/PolicyLink';
import ProductPromotion, { ProductPromotionPropsCs } from 'components/ProductPromotion';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  useValidConfirmationOffers,
  useValidPromocodeOffers,
} from 'helpers/discountHelpers';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import { CsHero, CsIcon, CsOffer, CsPromoCodeOffer } from 'types/contentStack';
import {
  DocumentsDispatchingDetails,
  FooterContainer,
  LargeText,
  QuoteDetails,
  StyledGridItem,
  StyledHeading,
  StyledPanel,
} from './styles';

type PromocodeOffer = {
  title: string;
  showPanel: boolean;
};

type ConfirmationProps = {
  data: {
    csPetConfirmation: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      next_button_url: string;
      quote_details: string;
      documents_dispatching_details: string;
      policy_wording_link: Link;
      bullet_icon: [CsIcon] | [];
      product_promotion: ProductPromotionPropsCs;
      promo_code_offer_text: string;
    };
    csPetOffers: {
      promocode_offers: CsPromoCodeOffer[];
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmation {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text
      next_button_url
      quote_details
      documents_dispatching_details
      policy_wording_link {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      bullet_icon {
        icon_code
      }
      product_promotion {
        label
        products {
          car_insurance {
            title
            href
          }
          home_insurance {
            title
            href
          }
          travel_insurance {
            title
            href
          }
        }
        arrow_icon {
          icon_code
        }
      }
      promo_code_offer_text
    }
    csPetOffers {
      promocode_offers {
        promocode
        qualified_multipet_suffix_text
        qualified_singlepet_suffix_text
      }
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps> = ({
  data: {
    csPetConfirmation: {
      hero,
      next_button_text,
      next_button_url,
      meta_title,
      quote_details,
      documents_dispatching_details,
      policy_wording_link,
      bullet_icon,
      product_promotion: { label, products, arrow_icon },
      promo_code_offer_text,
    },
    csPetOffers: { promocode_offers },
  },
}) => {
  const [quote] = useConfirmationQuote();
  const confirmationOffers = useValidConfirmationOffers();
  const validPromocodeOffers = useValidPromocodeOffers();

  usePageTracking(meta_title, !!quote, quote);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.extended);

  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  const [errorDispatched, setErrorDispatched] = useReducer(() => true, false);

  if (!quote) {
    if (!errorDispatched) {
      dispatchError({
        type: ERROR,
        errorType: ErrorType.CONFIRMATION_SESSION_EXPIRED,
      });
      setErrorDispatched();
    }

    return null;
  }

  const replaceQuotePlaceholders = replacePlaceholdersRichText(quotePlaceholders, quote);
  const icon = unwrapSingleton(bullet_icon)?.icon_code;
  const arrow = unwrapSingleton(arrow_icon)?.icon_code;

  const getPromocodeOffer = (): PromocodeOffer => {
    const isMultiPet = (quote?.petInfos?.length || 0) > 1;
    const isValidClubcard = !!quote.customerInfo?.clubcardNumber;
    const offerTemplate = {
      title: '',
      showPanel: false,
    };

    const matchedOffer = promocode_offers.find(
      (offer) =>
        offer.promocode?.toLowerCase() ===
        quote?.policyInfo?.promotionalCode?.toLowerCase()
    );

    if (matchedOffer && isValidClubcard && validPromocodeOffers?.length !== 0) {
      offerTemplate.showPanel = true;
      if (isMultiPet) {
        offerTemplate.title = `${matchedOffer.qualified_multipet_suffix_text}`;
      }
      if (!isMultiPet) {
        offerTemplate.title = `${matchedOffer.qualified_singlepet_suffix_text}`;
      }
    }

    return offerTemplate;
  };

  const promocodeOffer = getPromocodeOffer();

  return (
    <Layout
      heading={hero.heading}
      subheading={hero.subheading}
      metaTitle={meta_title}
      pageTitle={PageTitle.Confirmation}>
      <Grid alignLeft>
        <StyledGridItem desktop={9} tabletLandscape={9}>
          <QuoteDetails
            html={replaceQuotePlaceholders(quote_details)}
            pageTitle={PageTitle.Confirmation}
          />
          <DocumentsDispatchingDetails
            html={documents_dispatching_details}
            pageTitle={PageTitle.Confirmation}
          />
          <PolicyLink
            icon={icon}
            url={policy_wording_link.url}
            text={policy_wording_link.text}
            screenreaderText={policy_wording_link.screenreader_text ?? undefined}
            openInNewTab={policy_wording_link.open_in_new_tab}
            onClick={() =>
              trackTextButtonClick(
                PageTitle.Confirmation,
                'Policy wording document button'
              )
            }
          />
          {promocodeOffer.showPanel && (
            <StyledPanel data-cy="promocode-offer">
              <StyledHeading>{promocodeOffer.title}</StyledHeading>
              <Grid alignLeft>
                <GridItem desktop={9} tabletLandscape={9}>
                  <LargeText
                    html={promo_code_offer_text}
                    pageTitle={PageTitle.QuoteSummary}
                  />
                </GridItem>
              </Grid>
            </StyledPanel>
          )}
          {confirmationOffers &&
            confirmationOffers.map((offer: CsOffer, i: number) => (
              <DiscountPanel
                // eslint-disable-next-line react/no-array-index-key
                key={`${offer.promocode}-${i}`}
                offerPanel={offer}
                pageTitle={PageTitle.QuoteSummary}
              />
            ))}
          {Object.keys(products).length ? (
            <ProductPromotion label={label} products={products} iconCode={arrow} />
          ) : null}
        </StyledGridItem>
      </Grid>

      <FooterContainer>
        <FormFooter
          moveNextButton={{
            text: next_button_text,
            href: next_button_url,
            onClick: (e) => {
              handleAndTrackLinkClick(
                next_button_url,
                PageTitle.Confirmation,
                'Go to homepage',
                e
              );
            },
          }}
          pageTitle={PageTitle.Confirmation}
        />
      </FooterContainer>
    </Layout>
  );
};

export default Confirmation;
